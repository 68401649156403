import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { ContextProvider } from './Context/ContextProvider'
import './index.css'
import App from './App'

// console.log(process.env);

const process = {
  env: {
    STAGE: 'PROD'
  }
}

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.STAGE === 'PROD' ? 'us-east-1' : 'us-east-2',
    userPoolId:
    process.env.STAGE === 'PROD'
      ? 'us-east-1_a3Fk5S3hh'
      : 'us-east-2_L3E5BSjIf',
  identityPoolId:
    process.env.STAGE === 'PROD'
      ? 'us-east-1:a68cac30-d7f7-4f73-9b1f-ca6a4f86eba6'
      : 'us-east-2:9b1fda39-3231-4606-b32f-7ba24edcb53d',
  userPoolWebClientId:
    process.env.STAGE === 'PROD'
      ? 'jevucp6v2ehehqerq0rlgn4d8'
      : '5pqia04a65b3ef6fbmpoccj4vl',
    oauth: {
      // domain: `${"gymnaism-harshnew-auth-domain.auth.ap-south-1.amazoncognito.com"}`,
      // scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      // redirectSignIn: "http://localhost:3000",
      // redirectSignOut: "http://localhost:3000",
      // redirectSignIn: "https://gymnaism.netlify.app",
      // redirectSignOut: "https://gymnaism.netlify.app",
      responseType: 'token'
    }
  },
  // Storage: [
  //   {
  //     region: "ap-south-1",
  //     bucket: "harshairborn-gymnaism-me-useraccessbucketc6094d94-jlf4r2t4q6wz",
  //     identityPoolId: "ap-south-1:68cc0fa6-065b-4bff-b41b-07751bb8609b",
  //   },
  // ],
  Storage: {
    region: "us-east-1",
    bucket: "institution-utils",
    identityPoolId:
      process.env.STAGE === "PROD"
      ?   'us-east-1:a68cac30-d7f7-4f73-9b1f-ca6a4f86eba6'
     : 'us-east-2:9b1fda39-3231-4606-b32f-7ba24edcb53d'
  },
  API: {
    endpoints: [
      {
        name: 'user',
        endpoint:
          process.env.STAGE === 'PROD'
            ? 'https://xc5rnclqr6.execute-api.us-east-1.amazonaws.com/dev'
            : 'https://r5dp21mb28.execute-api.us-east-2.amazonaws.com/dev',
        region: process.env.STAGE === 'PROD' ? 'us-east-1' : 'us-east-2'
      }
    ]
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>
)
